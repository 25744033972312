import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "src/components/Element/seo"
import { Consumer } from "src/utils/contexts"
import BackgroundImage from 'gatsby-background-image'

import "./index.css"

const Project = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title
  const config = data.allStrapiConfig.edges[0].node
  const posts = data.allStrapiProject.edges

  let posts_for_show = []
  posts.forEach((obj) => {
      posts_for_show.push(obj.node)
  })

  return (
    <Consumer>
      {({store, functions}) => (
        <>
          <SEO
            title={"プロジェクト - " + siteTitle}
            description={"過去のプロジェクト。" + config.site_description}
          />
          <div id="project_list" className="main-content">
            <div className="main-content-wrapper">
              <div className="page-content">
                <h1 className="page-content-title">プロジェクト</h1>
                <div className="project-list-wrapper">
                  <ul className="project-list">
                    {
                      posts_for_show.map((node) => {

                      let thumbnail_fluid
                      if(node.thumbnail.childImageSharp.fluid) {
                          thumbnail_fluid = node.thumbnail.childImageSharp.fluid
                      } else {
                          thumbnail_fluid = null
                      }

                      let content_with_img
                      if (node.content) {
                          content_with_img = node.content.replace(/\/uploads/g, store.config.api_uri + "/uploads")
                      } else {
                          content_with_img = node.content
                      }

                      return (
                        <li className="ol-item" key={node.id}>
                          <Link className="ol-item-link" to={"/project/" + node.uri}>
                            <h1>{ node.title }</h1>
                            <div className="ol-item-content">
                              <div className="ol-item-content-inner">
                                <div className="ol-item-inside">
                                  {thumbnail_fluid ?
                                    <BackgroundImage
                                    Tag="div"
                                    className="ol-item-i-icon"
                                    fluid={node.thumbnail.childImageSharp.fluid}
                                    >
                                      <div className="ol-item-i-thumb">
                                        <div className="ol-item-i-thumb-article"
                                            dangerouslySetInnerHTML={{__html: content_with_img}} />
                                        </div>
                                    </BackgroundImage>
                                  : null}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Consumer>
  )
}

export default Project

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    # https://strapi.io/documentation/3.0.0-alpha.x/guides/filters.html#available-operators
    allStrapiProject(
      sort: { fields: [date], order: DESC }
      #            limit: 10
      filter: {
        visible: {eq: true}
      }
    ) {
      edges {
        node {
          id
          title
          date
          content
          uri
          summary
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          visible
        }
      }
    }
    allStrapiConfig {
      edges {
        node {
          site_description
        }
      }
    }
  }
`
